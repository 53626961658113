<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ pageName }}</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-button v-if="role == 'dinkes' || role == 'admin' || role == 'super admin'" variant="primary" v-b-modal.modal-input-user><CIcon name="cil-plus" /> Tambah Data</b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>

            <b-alert dismissible fade :show="showing" :variant="variant">{{ msg }}</b-alert>

            <b-row>
              <b-col md="3">
                <b-form-group label="Per Halaman" label-for="per-page-select" label-cols-md="6" label-align-md="left" label-size="md" style="background-color: ">
                  <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="md"></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="6" offset-md="3">
                <b-form-group label="Cari" label-for="filter-input" label-cols-md="3" label-align-md="right" label-size="md">
                  <b-input-group size="md">
                    <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Ketik disini untuk mencari ..."></b-form-input>

                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''" variant="danger">Hapus</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <template #cell(actions)="item">
                    <b-button
                      v-if="role == 'dinkes' || role == 'admin' || role == 'super admin'"
                      variant="warning"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit-user
                      @click="is_data = item.item"
                      ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                    >

                    <b-button
                      v-if="role == 'dinkes' || role == 'admin' || role == 'super admin'"
                      variant="danger"
                      class="mr-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete-user
                      @click="is_data = item.item"
                      ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="5" offset-md="7">
                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <modal-input :fileName="file_name" :option_role="option_role" :option_puskesmas="option_puskesmas" @alertFromChild="triggerAlert($event), getDatas()" />
    <modal-edit :fileName="file_name" :data_edit="is_data" :option_role="option_role" :option_puskesmas="option_puskesmas" @alertFromChild="triggerAlert($event)" @tutupModal="getDatas()" />
    <modal-delete :fileName="file_name" :data_delete="is_data" @alertFromChild="triggerAlert($event), getDatas()" />
  </div>
</template>

<script>
import ModalDelete from "./modalDelete.vue";
import ModalInput from "./modalInput.vue";
import ModalEdit from "./modalEdit.vue";

export default {
  components: {
    ModalInput,
    ModalDelete,
    ModalEdit,
  },
  name: "user",
  data() {
    return {
      file_name: "user",
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "username",
          label: "Username",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_role",
          label: "Role",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "alamat",
          label: "Alamat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nama_puskesmas",
          label: "Puskesmas",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-3 text-center",
        },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,
      option_puskesmas: [],
      option_role: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    pageName() {
      let words = this.file_name.includes("ms_") ? this.file_name.replace("ms_", "").split("_") : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
    filterOn() {
      // console.log(this.fields.map(el => el.key))
      return this.fields.map((el) => el.key);
    },
    role() {
      return this.$store.state.user_role;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getDatas() {
      const vm = this;
      vm.tableBusy = true;
      let id_pus = localStorage.getItem('idp')
      let res = await vm.$axios.post(`/${vm.file_name}/list`, {
        ms_puskesmas_id : id_pus
      });
      console.log(vm.file_name, res);

      vm.items = res.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
        x.id = x.user_id;
      }

      vm.totalRows = vm.items.length;
      vm.tableBusy = false;

      let roles = await vm.$axios.post("ms_role/list");
      // console.log(roles.data.data, "roles");
      if (roles.data.status == 200) {
        if (roles.data.message == "sukses") {
          if (roles.data.data.length) {
            vm.option_role = roles.data.data.filter((item) => {
              item.text = item.nama_role.toUpperCase();
              item.value = item.ms_role_id;
              return vm.role != "super admin" ? item.nama_role != "super admin" && item.nama_role != "dinkes" : item;
              // return item;
            });
          }
        }
      }

      //! puskesmas
      let option_puskesmas = await vm.$axios.post("/puskesmas/list");
      console.log(option_puskesmas.data.data, "puskesmas");
      vm.option_puskesmas =
        option_puskesmas.data.status == 200
          ? option_puskesmas.data.data.map((x) => {
              return { value: x.id, text: x.nama_puskesmas };
            })
          : [];
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
