<template>
  <div>
    <b-modal
      id="modal-input-user"
      size="lg"
      centered
      :title="'Tambah Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="resetModal"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Username <span class="text-danger">*</span> </template>
          <b-form-input
            :state="checkIfValid('username')"
            type="text"
            v-model="$v.is_data.username.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Password <span class="text-danger">*</span> </template>

          <b-input-group>
            <b-form-input
              :state="checkIfValid('password')"
              :type="is_text ? 'text' : 'password'"
              v-model="$v.is_data.password.$model"
            ></b-form-input>

            <template #append>
              <b-input-group-text @click="is_text = !is_text">
                <b-icon :icon="is_text ? 'eye' : 'eyeSlash'"></b-icon>
              </b-input-group-text>
            </template>
          </b-input-group>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label> Role <span class="text-danger">*</span> </template>
          <b-form-select
            :state="checkIfValid('ms_role_id')"
            :options="option_role"
            v-model="$v.is_data.ms_role_id.$model"
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Nama <span class="text-danger">*</span> </template>
          <b-form-input
            :state="checkIfValid('nama')"
            type="text"
            v-model="$v.is_data.nama.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Alamat <span class="text-danger">*</span> </template>
          <b-form-input
            :state="checkIfValid('alamat')"
            type="text"
            v-model="$v.is_data.alamat.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> Puskesmas <span class="text-danger">*</span> </template>
          <b-form-select
            v-model="$v.is_data.puskesmas_id.$model"
            :options="option_puskesmas"
            :state="checkIfValid('puskesmas_id')"
            disabled
          ></b-form-select>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> NIP <span class="text-danger">*</span> </template>
          <b-form-input
            v-model="$v.is_data.nip.$model"
            type="text"
            :state="checkIfValid('nip')"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label> NIK <span class="text-danger">*</span> </template>
          <b-form-input
            v-model="$v.is_data.nik.$model"
            type="text"
            :state="checkIfValid('nik')"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input-user')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "modalInput",
  props: ["fileName", "option_role", "option_puskesmas"],
  data() {
    return {
      is_data: {
        username: null,
        password: null,
        // password_confirm: null,
        ms_role_id: null,
        nama: null,
        alamat: null,
        puskesmas_id: null,
        nip: null,
        nik: null,
      },
      src1: "",
      selected: null,
      busy: false,
      button: "Simpan",
      file_name: this.fileName,
      list_role_tenaga_medis: [
        "dokter",
        "bidan",
        "perawat",
        "sanitarian",
        "nutrisionist",
      ],
      is_text: false
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.is_data, null, 4);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_")
        ? this.file_name.replace("ms_", "").split("_")
        : this.file_name.split("_");
      let capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      return capitalizedWords.join(" ");
    },
  },
  mixins: [validationMixin],
  validations: {
    is_data: {
      username: { required },
      password: { required },
      // password_confirm: { requirePassword: requiredIf(this.is_data.password), samePassword: sameAs(this.is_data.password) },
      ms_role_id: { required },
      nama: { required },
      alamat: { required },
      puskesmas_id: { required },
      nip: { required },
      nik: { required },
    },
  },
  mounted() {
    const vm = this
    vm.resetModal()
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      console.log(vm.is_data);
      // username, password, role, nama, alamat, puskesmas_id
      vm.$axios
        .post(`/${vm.file_name}/register`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN " + vm.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-input-user");
            vm.resetModal();

            // // check apakah user tenaga medis?
            // let status = vm.checkRole(res.data.data);
            // // true = commit ke store
            // // buka master tenaga medis tambah data
            // if (status) {
            //   this.$store.commit("set_data_user", res.data.data);
            //   vm.$router.push({ path: "/master/tenaga_medis/" + res.data.data.id });
            // }
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetModal() {
      const vm = this
      vm.$v.$reset();
      vm.is_data = {
        username: null,
        password: null,
        // password_confirm: null,
        ms_role_id: null,
        role: null,
        nama: null,
        alamat: null,
        puskesmas_id: vm.$store.state.puskesmas_id,
        nip: null,
        nik: null,
      };
    },
    checkRole(val) {
      console.log(val.role);
      let is_tenaga_medis = false;
      for (let i = 0; i < this.list_role_tenaga_medis.length; i++) {
        const el = this.list_role_tenaga_medis[i];
        if (val.role === el) {
          is_tenaga_medis = true;
        }
      }
      return is_tenaga_medis;
    },
  },
};
</script>
