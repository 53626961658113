<template>
  <div>
    <b-modal
      id="modal-edit-user"
      size="lg"
      centered
      :title="'Update Data ' + pageName"
      header-bg-variant="primary"
      header-text-variant="light"
      @hidden="tutupModal"
    >
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Username <span class="text-danger">*</span>
        </template>
        <b-form-input
          :state="checkIfValid('username')"
          type="text"
          v-model="$v.is_data.username.$model"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Role <span class="text-danger">*</span> </template>
        <b-form-select
          v-model="$v.is_data.ms_role_id.$model"
          :options="option_role"
          :state="checkIfValid('ms_role_id')"
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Nama <span class="text-danger">*</span> </template>
        <b-form-input
          v-model="$v.is_data.nama.$model"
          :state="checkIfValid('nama')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label> Alamat <span class="text-danger">*</span> </template>
        <b-form-input
          v-model="$v.is_data.alamat.$model"
          :state="checkIfValid('alamat')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          Puskesmas <span class="text-danger">*</span>
        </template>
        <b-form-select
          v-model="$v.is_data.puskesmas_id.$model"
          :options="option_puskesmas"
          :state="checkIfValid('puskesmas_id')"
          disabled
        ></b-form-select>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          NIP <span class="text-danger">*</span>
        </template>
        <b-form-input
          v-model="$v.is_data.nip.$model"
          :state="checkIfValid('nip')"
        ></b-form-input>
      </b-form-group>
      <b-form-group label-cols-md="3">
        <template v-slot:label>
          NIK <span class="text-danger">*</span>
        </template>
        <b-form-input
          v-model="$v.is_data.nik.$model"
          :state="checkIfValid('nik')"
        ></b-form-input>
      </b-form-group>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit-user')">
          Batal
        </b-button>
        <b-button variant="primary" :disabled="busy || !isValid" @click="simpan()">{{
          button
        }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Multiselect from 'vue-multiselect'

export default {
  name: "modalEdit",
  props: [
    "fileName",
    "data_edit",
    "option_role",
    "option_puskesmas",
  ],
  // components: {
  //   Multiselect,
  // },
  data() {
    return {
      busy: false,
      button: "Simpan",
      is_data: null,
      file_name: this.fileName
    };
  },
  computed: {
    computed_val: {
      get() {
        return this.is_data;
      },
      set(newVal) {
        this.is_data = newVal;
      },
    },
    formString() {
      return JSON.stringify(this.data_edit, 4, null);
    },
    isValid() {
      return !this.$v.is_data.$invalid;
    },
    isDirty() {
      return this.$v.is_data.$anyDirty;
    },
    pageName() {
      let words = this.file_name.includes("ms_") ? this.file_name.replace("ms_", "").split("_") : this.file_name.split("_")
      let capitalizedWords = words.map((word) => { return word.charAt(0).toUpperCase() + word.slice(1); });
      return capitalizedWords.join(" ");
    },
  },
  watch: {
    data_edit(newVal) {
      this.is_data = newVal;
      // for (let i = 0; i < this.option_specialist.length; i++) {
      //   const el = this.option_specialist[i];
      //   if (el.value === this.is_data.role) {
      //     this.is_data.role = el
      //   }
      // }
    },
  },

  mixins: [validationMixin],
  validations: {
    is_data: {
      username: { required },
      ms_role_id: { required },
      nama: { required },
      alamat: { required },
      puskesmas_id: { required },
      nip: { required },
      nik: { required },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.is_data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.$axios
        .post(`/${vm.file_name}/update`, vm.is_data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENGUBAH " + this.pageName.toUpperCase(),
              showing: true,
            });
            vm.$bvModal.hide("modal-edit-user");
            vm.resetModal();
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    tutupModal() {
      this.$emit("tutupModal");
    },
    resetModal() {
      this.$v.$reset();
      // this.is_data = {
      //   username: null,
      //   ms_role_id: null,
      //   nama: null,
      //   alamat: null,
      //   puskesmas_id: null,
      //   nip: null,
      //   nik: null,
      // };
    },
  },
};
</script>
